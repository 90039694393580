import React from 'react';
import {connect} from 'react-redux'

import {updateCart} from '../../actions'

const CartItem = (props) => {
    const item = props.item

    const removeFromCart = () => {
        const newCart = props.currentCart.filter(cartItem => cartItem.id !== props.item.id)
        localStorage.setItem('cart', JSON.stringify(newCart))
        props.updateCart(newCart)
    }

    return (
        <li key={item.id}>
            <div className="cart-title">
                <img src={item.images[0]} />
                <strong> {item.name}</strong>
            </div>
            <div className="cart-options">
                {console.log(item)}
                {item.options.map(option => (
                    option.type === "Colour" ?
                    <p style={{color: option.chosen}}>{option.chosen}</p>
                    :
                    <p>{option.type}{': '}{option.chosen}</p>
            ))}
            <p>Price: £{item.price}   Quantity: {item.quantity}</p>
        </div><div className="cart-button">
                <button onClick={removeFromCart}>Remove from cart</button>
            </div><hr />
        </li>
    )
}

const mapStateToProps = (state) => {
    return {
        currentCart: state.cart.cartItems
    }
}

export default connect(mapStateToProps, {updateCart})(CartItem)
import React from 'react';

const Commissions = () => {
    return (
        <div className="background-image">
            <p>Sometimes you want something that can't be bought in a store. Actually thats how half our products came about! We are able to make pretty much anything you can imagine and where we can't do something, we almost certainly know someone who can.</p>
            <p>From slow lovingly crafted high quality items to crazy deadline, hugely ambitious projects, we can usually come up with a plan to suit your timeline and budget.</p>
            <p>please email: info@mandalastudios.co.uk with details of what you need</p>
        </div>

    )
}

export default Commissions
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';

import { applyFilter, applyOrder, applySearch } from '../../actions';

const Filter = (props) => {
    const filterList = props.items.map(item => item.cat2).filter((value, index, self) => self.indexOf(value) === index)
    const {initialCategory} = useParams()

    useEffect(() => {
        if (initialCategory && initialCategory !== props.filterCategory) {
            props.applyFilter(initialCategory)
        }
    }, [])

    const filterChange = (e) => {
        props.applyFilter(e.target.value)
    }

    const orderChange = (e) => {
        props.applyOrder(e.target.value)
    }

    const searchChange = (e) => {
        props.applySearch(e.target.value)
    }
    
    return (
        <div className="filter">
            <select name="filter" onChange={filterChange} value={props.filterCategory}>
                <option name="All" key="All" value="All Categories">All Products</option>
                {filterList.map((category) => <option name={category} key={category}>{category}</option>)}
            </select>
            <select name="order" onChange={orderChange} value={props.filterOrder}>
                <option name="default" value="default" key="default">Order By</option>
                <option name="price" value="price" key="price">Price</option>
                <option name="alphabetical" value="alphabetical" key="alphabetical">Alphabetical</option>
            </select>
            <input name="searchTerm" key="searchTerm" placeholder="search" onChange={searchChange} value={props.filterTerm} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        items: state.items.allItems,
        filterCategory: state.items.filterCategory,
        filterOrder: state.items.filterOrder,
        filterTerm: state.items.filterTerm
    }
}

export default connect(mapStateToProps, {applyFilter, applyOrder, applySearch})(Filter)
const data = [
	{
		id: 151,
		name: 'Antlers, Small',
		cat1: 'Foam',
		cat2: 'Antlers',
		price: 30,
		summary: 'Cast foam antlers',
		contents: '1 pair of small antlers',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A pair of cast polyurethane foam antlers. Approximately 6 inches long. These antlers are made from a mould of real antler and cast in a soft polyurethane hybrid foam. As a result, the product can take light impacts without breaking, whilst remaining rigid and not bouncy when worn. They are supplied unmounted, but can be mounted on an alice band or leather headband. Please contact us if you would like to mount them on a wig.',
		images: [
			'/images/Antler_main.png',
			'/images/Antler_Mount_1.jpg',
			'/images/Antler_Mount_2.jpg'
		],
		options: [
			{optionName: 'Type', selectedChoice: 'Type A', selectedPrice: 0, choices: [
				{choiceName: 'Type A', cost: 0},
				{choiceName: 'Type B', cost: 5},
				{choiceName: 'Type C', cost: 10},
				{choiceName: 'Type D', cost: 5},
				{choiceName: 'Type E', cost: 0}
			]},
			{optionName: 'Mounting', selectedChoice: 'Unmounted', selectedPrice: 0, choices: [
				{choiceName: 'Unmounted', cost: 0},
				{choiceName: 'Alice Band', cost: 5},
				{choiceName: 'Leather Headband', cost: 20},
			]}
	]},
	{
		id: 26,
		name: 'Large Horns',
		cat1: 'Foam',
		cat2: 'Horns',
		price: 75,
		summary: 'Large carved foam horns',
		contents: '1 pair of large horns ',
		shippingPoints: 100,
		status: 'active',
		needsPaint: true,
		description: 'Individually hand-crafted curved ram horns, built to your design. Size is approximately 10"-12". Created from low density flexible foam, airbrushed to a realistic finish. Can be mounted on a headband or an alice band according to your preference. Can also be built into a wig contact us for details.',
		images: [
			'/images/horns.jpg',
		],
		options: [
			{optionName: 'Mounting', selectedChoice: 'Unmounted', selectedPrice: 0, choices: [
				{choiceName: 'Unmounted', cost: 0},
				{choiceName: 'Alice Band', cost: 5},
				{choiceName: 'Leather Headband', cost: 20},
			]}
	]},
	{
		id: 8,
		name: 'Corpse Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Corpse balaclava mask',
		contents: '1 Corpse balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Corpse mask made from low ammonia latex mounted on a cotton balaclava. This mask can be used at the Profound Decisions Empire LRP game to represent the Draughir lineage.',
		images: [
			'/images/corpse.jpg',
		],
		options: [
			{optionName: 'Scarring', selectedChoice: 'No scar', selectedPrice: 0, choices: [
				{choiceName: 'No scar', cost: 0},
				{choiceName: 'Scar', cost: 5}
			]},
			{optionName: 'Piercing', selectedChoice: 'No piercing', selectedPrice: 0, choices: [
				{choiceName: 'No piercing', cost: 0},
				{choiceName: 'Piercing', cost: 5}
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Braided hair with dreadlocks', cost: 25},
				{choiceName: 'Loose mohawk hair', cost: 25}
			]},
			{optionName: 'Hair Colour', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Light brown hair', cost: 0},
				{choiceName: 'Dark brown hair', cost: 0},
				{choiceName: 'Dark blonde hair', cost: 0},
				{choiceName: 'Other hair', cost: 5}
			]}
	]},
	{
		id: 172,
		name: 'Goblin Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Goblin balaclava mask',
		contents: '1 Goblin balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Goblin mask made from low ammonia latex mounted on a cotton balaclava. Goblin masks can also be customised with hair, tattoos and scars according to your preference, prices in options are to give you an idea of cost please contact us for an exacting costing, hair particularly can vary a lot depending what you want.',
		images: [
			'/images/goblin.png',
		],
		options: [
			{optionName: 'Ears', selectedChoice: 'No Ears', selectedPrice: 0, choices: [
				{choiceName: 'No ears', cost: 0},
				{choiceName: 'Ears', cost: 5}
			]}
	]},
	{
		id: 150,
		name: 'Orc Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Orc balaclava mask',
		contents: '1 Orc balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		colourList: ['White', 'Dark Red', 'Dark brown', 'Black', 'Light Brown', 'Dark Green', 'Grey'],
		description: 'An orc mask made from low ammonia latex mounted on a cotton balaclava.  Orc masks can also be customised with hair, tattoos and scars according to your preference, prices in options are to give you an idea of cost please contact us for an exacting costing, hair particularly can vary a lot depending what you want. These are the masks Profound Decisions used for monster orcs at Empire LRP.',
		images: [
			'/images/balaclava_orc_1.jpg',
			'/images/balaclava_orc_2.jpg',
			'/images/balaclava_orc_3.jpg',
			'/images/balaclava_orc_4.jpg'
		],
		options: [
			{optionName: 'Scarring', selectedChoice: 'No scar', selectedPrice: 0, choices: [
				{choiceName: 'No scar', cost: 0},
				{choiceName: 'Scar', cost: 5}
			]},
			{optionName: 'Piercing', selectedChoice: 'No piercing', selectedPrice: 0, choices: [
				{choiceName: 'No piercing', cost: 0},
				{choiceName: 'Piercing', cost: 5}
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Braided hair with dreadlocks', cost: 25},
				{choiceName: 'Loose mohawk hair', cost: 25}
			]},
			{optionName: 'Hair Colour', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Light brown hair', cost: 0},
				{choiceName: 'Dark brown hair', cost: 0},
				{choiceName: 'Dark blonde hair', cost: 0},
				{choiceName: 'Other hair', cost: 0}
			]}
	]},
		{
		id: 177,
		name: 'Boar Mask',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Boar foam mask',
		contents: '1 Boar foam mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam boar mask. This can be ordered painted or furred according to your requirements. Masks are sold lined with felt and with an elastic strap. ',
		images: [
			'/images/boar.png',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Fur effect', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur dreadlocks', cost: 40},
				{choiceName: 'Fur hood', cost: 100}
			]},
	]},
		{
		id: 187,
		name: 'Orc Shaman Mask',
		cat1: 'Masks',
		cat2: 'Latex Masks',
		price: 65,
		summary: 'Orc Shaman overhead mask',
		contents: '1 Orc Shaman overhead mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A latex Orc Shaman overhead mask. The mask can be customised with hair, piercings, tattoos and scars according to your preference. Please email if you wish to discuss customisation options. The Orc Shaman was sculpted to 25""/63cm head circumference. Please be aware we cannot be certain a mask will fit you perfectly. Due to the natural latex we use, sizes can vary by 5-10%. "',
		images: [
			'/images/orc_shaman.png',
		],
		options: [
			{optionName: 'Scarring', selectedChoice: 'No scar', selectedPrice: 0, choices: [
				{choiceName: 'No scar', cost: 0},
				{choiceName: 'Scar', cost: 5}
			]},
			{optionName: 'Piercing', selectedChoice: 'No piercing', selectedPrice: 0, choices: [
				{choiceName: 'No piercing', cost: 0},
				{choiceName: 'Piercing', cost: 5}
			]},
			{optionName: 'Hair',  selectedChoice: 'No hair', selectedPrice: 0,choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Braided hair with dreadlocks', cost: 25},
				{choiceName: 'Loose mohawk hair', cost: 25}
			]},
			{optionName: 'Hair Colour', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Light brown hair', cost: 0},
				{choiceName: 'Dark brown hair', cost: 0},
				{choiceName: 'Dark blonde hair', cost: 0},
				{choiceName: 'Other hair', cost: 0}
			]},
	]},
		{
		id: 181,
		name: 'Orc Brute Mask',
		cat1: 'Masks',
		cat2: 'Latex Masks',
		price: 65,
		summary: 'Orc Brute overhead mask',
		contents: '1 Orc Brute overhead mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A latex Orc Brute overhead mask. The mask can be customised with hair, piercings, tattoos and scars according to your preference. Please email if you wish to discuss customisation options. The Orc Brute was sculpted to 26""/65cm head circumference. Please be aware we cannot be certain a mask will fit you perfectly. Due to the natural latex we use, sizes can vary by 5-10%.',
		images: [
			'/images/orc_brute.png',
		],
		options: [
			{optionName: 'Scarring', selectedChoice: 'No scar', selectedPrice: 0, choices: [
				{choiceName: 'No scar', cost: 0},
				{choiceName: 'Scar', cost: 5}
			]},
			{optionName: 'Piercing', selectedChoice: 'No piercing', selectedPrice: 0, choices: [
				{choiceName: 'No piercing', cost: 0},
				{choiceName: 'Piercing', cost: 5}
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Braided hair with dreadlocks', cost: 25},
				{choiceName: 'Loose mohawk hair', cost: 25}
			]},
			{optionName: 'Hair Colour', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Light brown hair', cost: 0},
				{choiceName: 'Dark brown hair', cost: 0},
				{choiceName: 'Dark blonde hair', cost: 0},
				{choiceName: 'Other hair', cost: 0}
			]},
	]},
	{
		id: 109,
		name: 'Kitchen Knives',
		cat1: 'Props',
		cat2: 'Foam',
		price: 75,
		summary: 'Kitchen Knives',
		contents: '1 set of Kitchen Knives',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A set of coreless chef\'s kitchen knives.',
		images: [
			'/images/kitchen_knives.jpg',
		],
		options: [],
	},
	{
		id: 216,
		name: 'Smithing Tools',
		cat1: 'Props',
		cat2: 'Foam',
		price: 75,
		summary: 'Smithing Tools',
		contents: '1 set of Smithing Tools',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A set of five laser-cut blacksmithing tools.',
		images: [
			'/images/smithing_tools.png',
		],
		options: [],
	},
	{
		id: 233,
		name: 'Changeling Starter Set',
		cat1: 'Bundle',
		cat2: 'Lineage',
		price: 35,
		summary: '1 Changeling Starter',
		contents: '1 Changeling Starter Set',
		shippingPoints: 20,
		status: 'active',
		needsPaint: false,
		description: 'This special starter bundle contains a pair of our painted cast-foam antlers on Alice band, a pair of unpainted elf ears, and a set of unpainted spiral scar prosthetics. The spiral scars are provided untrimmed for you to customise as required.',
		images: [
			'/images/Changeling_Starter.jpg',
		],
		options: []
	},
	{
		id: 234,
		name: 'Cambion Starter Set',
		cat1: 'Bundle',
		cat2: 'Lineage',
		price: 36,
		summary: '1 Cambion Starter',
		contents: '1 Cambion Starter Set',
		shippingPoints: 20,
		status: 'active',
		needsPaint: false,
		description: 'This special starter bundle contains a pair of our small painted foam horns on Alice band, a set of painted fake nails (with glue and adhesive tabs), and a set of unpainted Labyrinth scar prosthetics. The horns and nails will be painted either Gold, Brass, Silver or Gunmetal. The Labyrinth scars are provided untrimmed for you to customise as required."',
		images: [
			'/images/Cambion_Starter.jpg',
		],
		options: []
	},
	{
		id: 235,
		name: 'Merrow Starter Set',
		cat1: 'Bundle',
		cat2: 'Lineage',
		price: 42,
		summary: '1 Merrow Starter',
		contents: '1 Merrow Starter Set',
		shippingPoints: 5,
		status: 'active',
		needsPaint: false,
		description: 'This special starter bundle contains a pair of painted Merfolk Barbels, set of painted Merfolk Scales, a pair of painted Merfolk Gills, and a tub of blue creme body paint. All prosthetics will be painted to match either our Blue, Light Blue, or Aqua creme body paints. This bundle will all be painted to match the make up provided (dark blue, light blue or aqua)',
		images: [
			'/images/Merrow3.jpg',
			'/images/Merrow4.jpg',
		],
		options: []
	},
	{
		id: 238,
		name: 'Draughir Starter Set',
		cat1: 'Bundle',
		cat2: 'Lineage',
		price: 32,
		summary: '1 Draughir Starter',
		contents: '1 Draughir Starter Set',
		shippingPoints: 5,
		status: 'active',
		needsPaint: false,
		description: 'This special starter bundle contains a pair of painted sunken eye prosthetics, a set of painted fake nails, a set of teeth and a tub of white creme body paint. We are unable to offer customisation and painting options on this bundle.',
		images: [
			'/images/DraughirStarter.jpg',
		],
		options: []
	},
	{
		id: 237,
		name: 'Briar Starter Set',
		cat1: 'Bundle',
		cat2: 'Lineage',
		price: 25,
		summary: '1 Briar Starter',
		contents: '1 Briar Starter Set',
		shippingPoints: 5,
		status: 'active',
		needsPaint: false,
		description: 'This special starter bundle contains a selection of our Dryad Bark and Dryad Thorny Bark prosthetics. We are unable to offer customisation and painting options on this bundle.',
		images: [
			'/images/Briar_Bark.png',
			'/images/Thorn_Bark.png'
		],
		options: []
	},
	{
		id: 236,
		name: 'Naga Starter Set',
		cat1: 'Bundle',
		cat2: 'Lineage',
		price: 32,
		summary: '1 Naga Starter',
		contents: '1 Naga Starter Set',
		shippingPoints: 5,
		status: 'active',
		needsPaint: false,
		description: 'This special starter bundle contains a selection of our Dryad Bark and Dryad Thorny Bark prosthetics. We are unable to offer customisation and painting options on this bundle.',
		images: [
			'/images/naga_set.jpg',
		],
		options: []
	},
	{
		id: 147,
		name: 'Wizard Hat',
		cat1: 'Costume',
		cat2: 'Hat',
		price: 80,
		summary: 'A wizard style hat',
		contents: '1 wizard hat',
		shippingPoints: 100,
		status: 'active',
		needsPaint: false,
		description: 'A leather Wizard\'s Hat, somewhat twisted and battered as if well used. ',
		images: [
			'/images/ComingSoon.jpg',
		],
		options: []
	},
	{
		id: 129,
		name: 'Antlers, medium',
		cat1: 'Foam',
		cat2: 'Antlers',
		price: 75,
		summary: 'Cast foam antlers',
		contents: '1 pair of medium antlers',
		shippingPoints: 100,
		status: 'active',
		needsPaint: true,
		description: 'A pair of hand-carved foam antlers. Approximately 12"-15" long. These antlers are made from a lightweight low density flexible foam, and airbrushed to achieve a realistic finish. They can be mounted on a leather headband, a thin alice band or built into a wig depending upon your requirements.',
		images: [
			'/images/antlersM.jpg',
		],
		options: [
			{optionName: 'Mounting', selectedChoice: 'Unmounted', selectedPrice: 0, choices: [
				{choiceName: 'Unmounted', cost: 0},
				{choiceName: 'Alice Band', cost: 5},
				{choiceName: 'Leather Headband', cost: 20},
			]}
	]},
	{
		id: 20,
		name: 'Antlers, large',
		cat1: 'Foam',
		cat2: 'Antlers',
		price: 85,
		summary: 'Cast foam antlers',
		contents: '1 pair of large foam antlers',
		shippingPoints: 100,
		status: 'active',
		needsPaint: true,
		description: 'A pair of hand-carved foam antlers. Approximately 15"-18" long. These antlers are made from a lightweight low density flexible foam, and airbrushed to achieve a realistic finish. They can be mounted on a leather headband, a thin alice band or built into a wig depending upon your requirements.',
		images: [
			'/images/antlersL.jpg',
		],
		options: [
			{optionName: 'Mounting', selectedChoice: 'Unmounted', selectedPrice: 0, choices: [
				{choiceName: 'Unmounted', cost: 0},
				{choiceName: 'Alice Band', cost: 5},
				{choiceName: 'Leather Headband', cost: 20},
			]}
	]},
	{
		id: 24,
		name: 'Curved Horns',
		cat1: 'Foam',
		cat2: 'Horns',
		price: 80,
		summary: 'Curved horns',
		contents: '1 pair of curved horns',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'Individually hand crafted curved horns, built to your design. Hand carved from low density flexible foam, airbrushed to a realistic finish. Can be mounted on a headband or an alice band. Also available separately to be incorporated into a hat. Built into a wig is also possible contact us for details.',
		images: [
			'/images/curvedHorns1.jpg',
			'/images/curvedHorns2.jpg',
			'/images/curvedHorns3.jpg',
		],
		options: [
			{optionName: 'Mounting', selectedChoice: 'Unmounted', selectedPrice: 0, choices: [
				{choiceName: 'Unmounted', cost: 0},
				{choiceName: 'Alice Band', cost: 5},
				{choiceName: 'Leather Headband', cost: 20},
			]}
	]},
	{
		id: 156,
		name: 'Small Horns',
		cat1: 'Foam',
		cat2: 'Horns',
		price: 35,
		summary: 'Small horns',
		contents: '1 pair of small horns',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'Individually hand crafted horns, built to your design. Small size is approximately 2"-4". Created from low density flexible foam, airbrushed to a realistic finish. Can be mounted on a headband or an alice band according to your preference. Can also be built into a wig contact us for details.',
		images: [
			'/images/smallHorns.jpg',
		],
		options: [
			{optionName: 'Mounting', selectedChoice: 'Unmounted', selectedPrice: 0, choices: [
				{choiceName: 'Unmounted', cost: 0},
				{choiceName: 'Alice Band', cost: 5},
				{choiceName: 'Leather Headband', cost: 20},
			]}
	]},
	{
		id: 155,
		name: 'Medium Horns',
		cat1: 'Foam',
		cat2: 'Horns',
		price: 60,
		summary: 'Medium horns',
		contents: '1 pair of medium horns',
		shippingPoints: 100,
		status: 'active',
		needsPaint: true,
		description: 'Individually hand-crafted curved ram horns, built to your design. Medium size is approximately 6"-8". Created from low density flexible foam, airbrushed to a realistic finish. Can be mounted on a headband or an alice band according to your preference. Can also be built into a wig contact us for details.',
		images: [
			'/images/smallHorns.jpg',
		],
		options: [
			{optionName: 'Mounting', selectedChoice: 'Unmounted', selectedPrice: 0, choices: [
				{choiceName: 'Unmounted', cost: 0},
				{choiceName: 'Alice Band', cost: 5},
				{choiceName: 'Leather Headband', cost: 20},
			]}
	]},
	{
		id: 220,
		name: 'Demon Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Demon balaclava mask',
		contents: '1 Demon balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Demon mask made from low ammonia latex mounted on a cotton balaclava.',
		images: [
			'/images/demon_1.jpg',
		],
		options: []
	},
	{
		id: 217,
		name: 'Devil Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Devil balaclava mask',
		contents: '1 Devil balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Devil mask made from low ammonia latex mounted on a cotton balaclava.',
		images: [
			'/images/devil.jpg',
		],
		options: []
	},
	{
		id: 230,
		name: 'Fishy Alien Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Fishy alien balaclava mask',
		contents: '1 Fishy Alien balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Fishy Alien mask made from low ammonia latex mounted on a cotton balaclava.',
		images: [
			'/images/fishyAlien.jpg',
		],
		options: []
	},
	{
		id: 191,
		name: 'Gremlin Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Gremiln balaclava mask',
		contents: '1 Gremlin balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Gremlin mask made from low ammonia latex mounted on a cotton balaclava.',
		images: [
			'/images/gremlin.jpg',
		],
		options: []
	},
	{
		id: 195,
		name: 'Husk Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Husk balaclava mask',
		contents: '1 Husk balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Husk mask made from low ammonia latex mounted on a cotton balaclava. This mask can be used at the Profound Decisions Empire LRP game to represent the Draughir lineage.',
		images: [
			'/images/husk1.jpg',
			'/images/husk2.jpg',
		],
		options: [{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
			{choiceName: 'No hair', cost: 0},
			{choiceName: 'Braided dreadlock mohawk', cost: 25},
			{choiceName: 'Loose mohawk hair', cost: 25}
		]},
		{optionName: 'Hair Colour', selectedChoice: 'No hair', selectedPrice: 0, choices: [
			{choiceName: 'No hair', cost: 0},
			{choiceName: 'Light brown hair', cost: 0},
			{choiceName: 'Dark brown hair', cost: 0},
			{choiceName: 'Dark blonde hair', cost: 0},
			{choiceName: 'Other hair', cost: 0}
		]}
	]},
	{
		id: 219,
		name: 'Bat Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Bat balaclava mask',
		contents: '1 Bat balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Bat mask made from low ammonia latex mounted on a cotton balaclava.',
		images: [
			'/images/bat.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
			]},
			{optionName: 'Ears', selectedChoice: 'No ears', selectedPrice: 0, choices: [
				{choiceName: 'No ears', cost: 0},
				{choiceName: 'With ears', cost: 5}
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'Braided dreadlock mohawk', cost: 25},
				{choiceName: 'Loose hair mohawk', cost: 25},
			]}
		]
	},
	{
		id: 227,
		name: 'Mantis Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Mantis balaclava mask',
		contents: '1 Mantis balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Mantis mask made from low ammonia latex mounted on a cotton balaclava.',
		images: [
			'/images/mantis.jpg',
		],
		options: []
	},
	{
		id: 19,
		name: 'Merfolk Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Merfolk balaclava mask',
		contents: '1 Merfolk balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Merfolk mask made from low ammonia latex mounted on a cotton balaclava.  This mask can be used at the Profound Decisions Empire LRP game to represent the Merrow lineage.',
		images: [
			'/images/merfolk1.jpg',
			'/images/merfolk2.jpg',
		],
		options: [
			{optionName: 'Gills', selectedChoice: 'No gills', selectedPrice: 0, choices: [
				{choiceName: 'No gills', cost: 0},
				{choiceName: 'With gille', cost: 5},
			]},
			{optionName: 'Ears', selectedChoice: 'No ears', selectedPrice: 0, choices: [
				{choiceName: 'No ears', cost: 0},
				{choiceName: 'With ears', cost: 5},
			]}
		]
	},
	{
		id: 171,
		name: 'Serpent Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Serpent balaclava mask',
		contents: '1 Serpent balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Serpent mask made from low ammonia latex mounted on a cotton balaclava.  This mask can be used at the Profound Decisions Empire LRP game to represent the Naga lineage.',
		images: [
			'/images/serpent1.jpg',
			'/images/serpent2.png',
			'/images/serpent3.png',
		],
		options: []
	},
	{
		id: 218,
		name: 'Skull Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Skull balaclava mask',
		contents: '1 Skull balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Skull mask made from low ammonia latex mounted on a cotton balaclava.',
		images: [
			'/images/skull.jpg',
		],
		options: []
	},
	{
		id: 188,
		name: 'Spider Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Spider balaclava mask',
		contents: '1 Spider balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Spider mask made from low ammonia latex mounted on a cotton balaclava.',
		images: [
			'/images/spider1.jpg',
			'/images/spider2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20}
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur dreadlocks', cost: 40}
			]}
		]
	},
	{
		id: 190,
		name: 'Werewolf Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Werewolf balaclava mask',
		contents: '1 Werewolf balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A Werewolf mask made from low ammonia latex mounted on a cotton balaclava.',
		images: [
			'/images/werewolf.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50}
			]},
			{optionName: 'Ears', selectedChoice: 'No ears', selectedPrice: 0, choices: [
				{choiceName: 'No ears', cost: 0},
				{choiceName: 'With ears', cost: 5}
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'Braided dreadlock mohawk', cost: 25},
				{choiceName: 'Loose hair mohawk', cost: 25},
			]}
		]
	},
	{
		id: 232,
		name: 'Orc initiate Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 40,
		summary: 'Orc initiate mask',
		contents: '1 random orc balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: false,
		description: 'An Orc mask made from low ammonia latex mounted on a cotton balaclava. We are not offering our normal range of customisation options for this product. The mask may include several random scars and will be painted in an olive grey that matches the colours we use on the masks for Empire LRP. Please see our other orc masks if you want customised colours, scars, piercings and hair options.',
		images: [
			'/images/initiate1.jpg',
			'/images/initiate2.jpg',
		],
		options: [
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Random hair', cost: 25},
			]}
		]
	},
	{
		id: 13,
		name: 'Ape Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Ape mask',
		contents: '1 Ape balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'Hand-made from low ammonia latex Ape face mounted on a cotton or lycra balaclava. The mask can be furred with either faux fur or NFT fur. Alternatively we can add a hair mohawk onto the mask.',
		images: [
			'/images/ape.jpg',
		],
		options: [{optionName: 'Finishing', selectedChoice: 'Painted', selectedPrice: 0, choices: [
			{choiceName: 'Painted', cost: 0},
			{choiceName: 'Flocked', cost: 20},
		]},
		{optionName: 'Ears', selectedChoice: 'No ears', selectedPrice: 0, choices: [
			{choiceName: 'No ears', cost: 0},
			{choiceName: 'With ears', cost: 10},
		]},
		{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
			{choiceName: 'No hair', cost: 0},
			{choiceName: 'Fur hood', cost: 100},
			{choiceName: 'NFT fur hood', cost: 250},
			{choiceName: 'Braided hair dreadlocks', cost: 25},
			{choiceName: 'Loose hair mohawk', cost: 25},
		]},
	]},
	{
		id: 201,
		name: 'Alien Hybrid Mask',
		cat1: 'Masks',
		cat2: 'Balaclava Masks',
		price: 45,
		summary: 'Alien hybrid mask',
		contents: '1 Alien hybrid balaclava mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A latex Alien Hybrid mask mounted on a lycra or cotton balaclava.',
		images: [
			'/images/alienHybrid1.png',
			'/images/alienHybrid2.png',
		],
		options: []
	},
	{
		id: 203,
		name: 'Rat',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam rat mask',
		contents: '1 Foam rat mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A rat mask made from latex and filled with foam. Please contact us to discuss furring options.',
		images: [
			'/images/Rat1.jpg',
			'/images/Rat2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'Furred', cost: 50}
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Loose hair mohawk', cost: 25},
				{choiceName: 'Dreadlock mohawk', cost: 25},
				{choiceName: 'Fur dreadlocks', cost: 40}
			]},
	]},
	{
		id: 175,
		name: 'Gargoyle',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam gargoyle mask',
		contents: '1 Foam gargoyle mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A Gargoyle foam mask. Covered in a latex skin and lined internally with felt.',
		images: [
			'/images/Gargoyle.jpg',
		],
		options: []
	},
	{
		id: 59,
		name: 'Bear',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam bear mask',
		contents: '1 Foam bear mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam bear mask. The mask can be painted or furred according to your requirements. Each mask is lined with felt and includes an elastic strap. We can add loose hair or a dreadlock mohawk to the mask, hiding the back of your head. Alternatively, we can build the mask with a fur hood as part of the furring process that will cover your entire head and neck. Please enquire if you would like to add an articulated jaw and teeth to the mask.',
		images: [
			'/images/Bear1.jpg',
			'/images/Bear2.jpg',
			'/images/Bear3.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50}
			]},
			{optionName: 'Lower Jaw', selectedChoice: 'No lower jaw', selectedPrice: 0, choices: [
				{choiceName: 'No lower jaw', cost: 0},
				{choiceName: 'Lower jaw', cost: 25},
				{choiceName: 'Flocked lower jaw', cost: 35},
				{choiceName: 'Furred lower jaw', cost: 50}
			]},
		]},
	{
		id: 231,
		name: 'Triceratops',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 110,
		summary: 'Foam ticeratops mask',
		contents: '1 Foam triceratops mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam Ceratopsian mask. This can be ordered painted or feathered according to your requirements. Masks are sold lined with felt and with an elastic strap. Dreadlocks can be attached to this mask to cover the back of the head.',
		images: [
			'/images/ComingSoon.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Feathered', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Dreadlocks', cost: 25},
			]},
	]},
	{
		id: 49,
		name: 'Cougar',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam cougar mask',
		contents: '1 Foam cougar mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam cougar mask. This can be ordered painted or furred according to your requirements. Masks are sold lined with felt and with an elastic strap. ',
		images: [
			'/images/Cougar1.jpg',
			'/images/Cougar2.jpg',
			'/images/Cougar3.jpg',
			'/images/Cougar4.jpg',
			'/images/Cougar5.jpg',
			'/images/Cougar6.jpg',
			'/images/Cougar7.jpg',
			'/images/Cougar8.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur dreadlocks', cost: 40},
				{choiceName: 'Fur hood', cost: 100},
			]},
			{optionName: 'Lower Jaw', selectedChoice: 'No lower jaw', selectedPrice: 0, choices: [
				{choiceName: 'No lower jaw', cost: 0},
				{choiceName: 'Lower jaw', cost: 25},
				{choiceName: 'Flocked lower jaw', cost: 35},
				{choiceName: 'Furred lower jaw', cost: 50}
			]},
	]},
	{
		id: 61,
		name: 'Iguana',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam iguana mask',
		contents: '1 Foam iguana mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam Iguana mask. This can be ordered painted or furred according to your requirements. Masks are sold lined with felt and with an elastic strap. Dreadlocks can be attached to this mask to cover the back of the head.',
		images: [
			'/images/Iguana1.jpg',
		],
		options: [
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Dreadlocks', cost: 25},
			]},
	]},
	{
		id: 145,
		name: 'Lion',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam lion mask',
		contents: '1 Foam lion mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam lion mask. This can be ordered painted or furred according to your requirements. Masks are sold lined with felt and with an elastic strap. Dreadlocks can be attached to this mask to cover the back of the head. Alternatively a fur hood can be added.',
		images: [
			'/images/Lion1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'NFT fur mane', cost: 200},
				{choiceName: 'Fur dreadlocks', cost: 40},
			]},
			{optionName: 'Lower Jaw', selectedChoice: 'No lower jaw', selectedPrice: 0, choices: [
				{choiceName: 'No lower jaw', cost: 0},
				{choiceName: 'Lower jaw', cost: 25},
				{choiceName: 'Flocked lower jaw', cost: 35},
				{choiceName: 'Furred lower jaw', cost: 50}
			]},
			{optionName: 'Teeth', selectedChoice: 'No teeth', selectedPrice: 0, choices: [
				{choiceName: 'No teeth', cost: 0},
				{choiceName: 'Teeth', cost: 25},
			]},
	]},
	{
		id: 225,
		name: 'Otter',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam otter mask',
		contents: '1 Foam otter mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam otter mask. This can be ordered painted or furred according to your requirements. Masks are sold lined with felt and with an elastic strap. Dreadlocks can be attached to the mask to cover the back of the head. Alternatively a fur hood can be added.',
		images: [
			'/images/Otter1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'Fur dreadlocks', cost: 40},
			]},
	]},
	{
		id: 60,
		name: 'Panda',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam panda mask',
		contents: '1 Foam panda mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam Panda mask. This can be ordered painted or furred according to your requirements. Masks are sold lined with felt and with an elastic strap. Dreadlocks can be attached to this mask to cover the back of the head.',
		images: [
			'/images/Panda1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'Fur dreadlocks', cost: 40},
			]},
	]},
	{
		id: 56,
		name: 'Fox',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam fox mask',
		contents: '1 Foam fox mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam Fox mask. This can be ordered painted or furred according to your requirements. Masks are sold lined with felt and with an elastic strap. Dreadlocks can be attached to this mask to cover the back of the head.',
		images: [
			'/images/Fox1.jpg',
			'/images/Fox2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'Fur dreadlocks', cost: 40},
			]},
			{optionName: 'Lower Jaw', selectedChoice: 'No lower jaw', selectedPrice: 0, choices: [
				{choiceName: 'No lower jaw', cost: 0},
				{choiceName: 'Lower jaw', cost: 25},
				{choiceName: 'Flocked lower jaw', cost: 35},
				{choiceName: 'Furred lower jaw', cost: 50}
			]},
	]},
	{
		id: 146,
		name: 'Stoat',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam stoat mask',
		contents: '1 Foam stoat mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam stoat mask. This can be ordered painted or furred according to your requirements. Masks are sold lined with felt and with an elastic strap. Dreadlocks can be attached to this mask to cover the back of the head. Alternatively a fur hood can be added.',
		images: [
			'/images/Stoat1.jpg',
			'/images/Stoat2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'Fur dreadlocks', cost: 40},
			]},
	]},
	{
		id: 57,
		name: 'Tauren',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 140,
		summary: 'Foam tauren mask',
		contents: '1 Foam tauren mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam Tauren mask. This can be ordered painted or furred according to your requirements. Masks are sold lined with felt and with an elastic strap. The horns are carved and painted polyurathane foam. Please specify horn style when ordering. Dreadlocks can be attached to this mask to cover the back of the head or a fur hood can be added.',
		images: [
			'/images/Tauren1.jpg',
			'/images/Tauren2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Furred', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'Fur dreadlocks', cost: 40},
			]},
	]},
	{
		id: 23,
		name: 'Wolf',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam wolf mask',
		contents: '1 Foam wolf mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam wolf mask. This can be ordered painted or furred according to your requirements. Masks are sold lined with felt and with an elastic strap. Dreadlocks can be attached to this mask to cover the back of the head. Alternatively a fur hood can be added.',
		images: [
			'/images/Wolf1.jpg',
			'/images/Wolf2.jpg',
			'/images/Wolf3.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'Fur dreadlocks', cost: 40},
			]},
			{optionName: 'Lower Jaw', selectedChoice: 'No lower jaw', selectedPrice: 0, choices: [
				{choiceName: 'No lower jaw', cost: 0},
				{choiceName: 'Lower jaw', cost: 25},
				{choiceName: 'Flocked lower jaw', cost: 35},
				{choiceName: 'Furred lower jaw', cost: 50}
			]},
			{optionName: 'Teeth', selectedChoice: 'No teeth', selectedPrice: 0, choices: [
				{choiceName: 'No teeth', cost: 0},
				{choiceName: 'Teeth', cost: 25},
			]},
	]},
	{
		id: 207,
		name: 'Zebra',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 100,
		summary: 'Foam zebra mask',
		contents: '1 Foam zebra mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A latex and foam Zebra mask. Price includes ears. This mask can also be used as a base for a horse or donkey mask.',
		images: [
			'/images/Zebra1.jpg',
			'/images/Zebra2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'NFT fur mane', cost: 100},
				{choiceName: 'Fur dreadlocks', cost: 40},
				{choiceName: 'Loose hair mohawk', cost: 25},
				{choiceName: 'Dreadlock mohawk', cost: 25},
			]},
	]},
	{
		id: 178,
		name: 'Meerkat',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam meerkat mask',
		contents: '1 Foam meerkat mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A meerkat foam mask',
		images: [
			'/images/Meerkat1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
				{choiceName: 'Furred', cost: 50},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'Fur dreadlocks', cost: 40},
			]},
	]},
	{
		id: 176,
		name: 'Sheep',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam sheep mask',
		contents: '1 Foam sheep mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A Sheep foam mask',
		images: [
			'/images/Sheep1.jpg',
			'/images/Sheep2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Painted', selectedPrice: 0, choices: [
				{choiceName: 'Painted', cost: 0},
				{choiceName: 'Flocked', cost: 20},
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Fur hood', cost: 100},
				{choiceName: 'Fur dreadlocks', cost: 40},
			]},
	]},
	{
		id: 204,
		name: 'Vulture',
		cat1: 'Masks',
		cat2: 'Foam Masks',
		price: 85,
		summary: 'Foam vulture mask',
		contents: '1 Foam vulture mask',
		shippingPoints: 20,
		status: 'active',
		needsPaint: true,
		description: 'A vulture mask made from latex and filled with foam.',
		images: [
			'/images/Otter1.jpg',
		],
		options: []
	},
	{
		id: 229,
		name: 'Orc Pioneer',
		cat1: 'Masks',
		cat2: 'Latex Masks',
		price: 65,
		summary: 'Orc Pioneer overhead mask',
		contents: '1 Orc Pioneer overhead mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A latex Orc Pioneer overhead mask. The mask can be customised with hair, piercings, tattoos and scars according to your preference. Please email if you wish to discuss customisation options.',
		images: [
			'/images/Pioneer1.jpg',
			'/images/Pioneer2.jpg',
		],
		options: [
			{optionName: 'Scarring', selectedChoice: 'No scar', selectedPrice: 0, choices: [
				{choiceName: 'No scar', cost: 0},
				{choiceName: 'Scar', cost: 5}
			]},
			{optionName: 'Piercing', selectedChoice: 'No piercing', selectedPrice: 0, choices: [
				{choiceName: 'No piercing', cost: 0},
				{choiceName: 'Piercing', cost: 5}
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Braided hair with dreadlocks', cost: 25},
				{choiceName: 'Loose mohawk hair', cost: 25}
			]},
			{optionName: 'Hair Colour', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Light brown hair', cost: 0},
				{choiceName: 'Dark brown hair', cost: 0},
				{choiceName: 'Dark blonde hair', cost: 0},
				{choiceName: 'Other hair', cost: 5}
			]}
	]},
	{
		id: 189,
		name: 'Orc Rogue',
		cat1: 'Masks',
		cat2: 'Latex Masks',
		price: 65,
		summary: 'Orc Rogue overhead mask',
		contents: '1 Orc Rogue overhead mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A latex Orc Rogue overhead mask. The mask can be customised with hair, piercings, tattoos and scars according to your preference. Please email if you wish to discuss customisation options. The Orc Rogue was sculpted to 22.5""/57cm head circumference. Please be aware we cannot be certain a mask will fit you perfectly. Due to the natural latex we use, sizes can vary by 5-10%.',
		images: [
			'/images/Rogue1.jpg',
			'/images/Rogue2.jpg',
		],
		options: [
			{optionName: 'Scarring', selectedChoice: 'No scar', selectedPrice: 0, choices: [
				{choiceName: 'No scar', cost: 0},
				{choiceName: 'Scar', cost: 5}
			]},
			{optionName: 'Piercing', selectedChoice: 'No piercing', selectedPrice: 0, choices: [
				{choiceName: 'No piercing', cost: 0},
				{choiceName: 'Piercing', cost: 5}
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Braided hair with dreadlocks', cost: 25},
				{choiceName: 'Loose mohawk hair', cost: 25}
			]},
			{optionName: 'Hair Colour', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Light brown hair', cost: 0},
				{choiceName: 'Dark brown hair', cost: 0},
				{choiceName: 'Dark blonde hair', cost: 0},
				{choiceName: 'Other hair', cost: 5}
			]}
	]},
	{
		id: 63,
		name: 'Zombie',
		cat1: 'Masks',
		cat2: 'Latex Masks',
		price: 65,
		summary: 'Zombie overhead mask',
		contents: '1 Zombie overhead mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'An overhead mask made from low ammonia latex.',
		images: [
			'/images/Zombie1.jpg',
			'/images/Zombie2.jpg',
			'/images/Zombie3.jpg',
			'/images/Zombie4.jpg',
			'/images/Zombie5.jpg',
		],
		options: []
	},
	{
		id: 9,
		name: 'Orc Hero',
		cat1: 'Masks',
		cat2: 'Latex Masks',
		price: 65,
		summary: 'Orc Hero overhead mask',
		contents: '1 Orc Hero overhead mask',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'Our original latex Orc Hero overhead mask. The mask can be customised with hair, piercings, tattoos and scars according to your preference. Please email if you wish to discuss customisation options. We have two sizes of sculpts  our original orc hero (23inches/59cm) and our small hero (22inches/56cm). Please be aware we cannot be certain a mask will fit you perfectly. Due to the natural latex we use, sizes can vary by 5-10%.  We have two sizes of sculpts  our original orc hero (23""/59cm) and our small hero (22""/56cm). Please be aware we cannot be certain a mask will fit you perfectly. Due to the natural latex we use, sizes can vary by 5-10%.',
		images: [
			'/images/Hero1.jpg',
			'/images/Hero2.jpg',
			'/images/Hero3.jpg',
			'/images/Hero4.jpg',
			'/images/Hero5.jpg',
			'/images/Hero6.jpg',
			'/images/Hero7.jpg',
			'/images/Hero8.jpg',
			'/images/Hero9.jpg',
			'/images/Hero10.jpg',
		],
		options: [
			{optionName: 'Scarring', selectedChoice: 'No scar', selectedPrice: 0, choices: [
				{choiceName: 'No scar', cost: 0},
				{choiceName: 'Scar', cost: 5}
			]},
			{optionName: 'Piercing', selectedChoice: 'No piercing', selectedPrice: 0, choices: [
				{choiceName: 'No piercing', cost: 0},
				{choiceName: 'Piercing', cost: 5}
			]},
			{optionName: 'Hair', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Braided hair with dreadlocks', cost: 25},
				{choiceName: 'Loose mohawk hair', cost: 25}
			]},
			{optionName: 'Hair Colour', selectedChoice: 'No hair', selectedPrice: 0, choices: [
				{choiceName: 'No hair', cost: 0},
				{choiceName: 'Light brown hair', cost: 0},
				{choiceName: 'Dark brown hair', cost: 0},
				{choiceName: 'Dark blonde hair', cost: 0},
				{choiceName: 'Other hair', cost: 5}
			]}
	]},
	{
		id: 192,
		name: 'Ghast',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Ghast prosthetic mask',
		contents: '1 Ghast prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'An undead Ghast mask made from low-ammonia latex. Can be supplied with an elastic strap.',
		images: [
			'/images/ComingSoon.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 21,
		name: 'Mustalid',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Mustalid prosthetic mask',
		contents: '1 Mustalid prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex Mustalid prosthetic mask has been designed for use in theatrical products, cosPlay or LARP games. Mandala Studios advises for the best effect to affix the prosthetic to the face using standard prosthetic adhesive. Alternatively there is the option of adding a strap for ease of use.',
		images: [
			'/images/Mustalid.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 36,
		name: 'Owl',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Owl prosthetic mask',
		contents: '1 Owl prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex owl prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Owl1.jpg',
			'/images/Owl2.jpg',
			'/images/Owl3.jpg',
			'/images/Owl4.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 40,
		name: 'Pig',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Pig prosthetic mask',
		contents: '1 Pig prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex pig prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Pig1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 33,
		name: 'Rabbit',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Rabbit prosthetic mask',
		contents: '1 Rabbit prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex rabbit prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Rabbit1.jpg',
			'/images/Rabbit2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 193,
		name: 'Crone',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Crone prosthetic mask',
		contents: '1 Crone prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A Crone prosthetic mask. Made from low-ammonia latex. Can be supplied with an elastic strap.',
		images: [
			'/images/ComingSoon.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 30,
		name: 'Beast',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Beast prosthetic mask',
		contents: '1 Beast prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex beast prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Beast1.jpg',
			'/images/Beast2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 43,
		name: 'Dragonborn',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Dragonborn prosthetic mask',
		contents: '1 Dragonborn prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex dragonborn prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Dragonborn1.jpg',
			'/images/Dragonborn2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 42,
		name: 'Dragonkin',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Dragonkin prosthetic mask',
		contents: '1 Dragonkin prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex dragonkin prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Dragonkin.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 11,
		name: 'Duck',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Duck prosthetic mask',
		contents: '1 Duck prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex duck prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Duck1.jpg',
			'/images/Duck2.jpg',
			'/images/Duck3.jpg',
			'/images/Duck4.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 31,
		name: 'Feline',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Feline prosthetic mask',
		contents: '1 Feline prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex feline prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Feline1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 28,
		name: 'Finch',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Finch prosthetic mask',
		contents: '1 Finch prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex finch prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Finch.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 41,
		name: 'Frog',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Frog prosthetic mask',
		contents: '1 Frog prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex frog prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Frog1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 148,
		name: 'Gecko',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Gecko prosthetic mask',
		contents: '1 Gecko prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex gecko prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap. ',
		images: [
			'/images/Gecko1.jpg',
			'/images/Gecko2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 34,
		name: 'Hedgehog',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Hedgehog prosthetic mask',
		contents: '1 Hedgehog prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex hedgehog prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Hedgehog1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 38,
		name: 'Insect',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Insect prosthetic mask',
		contents: '1 Insect prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex insect prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Insect.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 149,
		name: 'Mamba',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Mamba prosthetic mask',
		contents: '1 Mamba prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex Mamba snake prosthetic. This can be ordered unpainted, or painted according to your requirements. This prosthetic can be attached using prosthetic adhesive. Available in normal and a petite or child size.',
		images: [
			'/images/Mamba.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 35,
		name: 'Mole',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 20,
		summary: 'Mole prosthetic mask',
		contents: '1 Mole prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex mole prosthetic mask. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Mole1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 22,
		name: 'Rhinosaurus',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 30,
		summary: 'Rhinosaurus prosthetic mask',
		contents: '1 Rhinosaurus prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex rhinosaurus prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Rhinosaurus1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 16,
		name: 'Snake',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 30,
		summary: 'Snake prosthetic mask',
		contents: '1 Snake prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex snake prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Snake1.jpg',
			'/images/Snake2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 32,
		name: 'Spider',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 30,
		summary: 'Spider prosthetic mask',
		contents: '1 Spider prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex spider prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/ComingSoon.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 39,
		name: 'Walrus',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 45,
		summary: 'Walrus prosthetic mask',
		contents: '1 Walrus prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex walrus prosthetic. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Walrus1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 17,
		name: 'Dryad',
		cat1: 'Prosthetics',
		cat2: 'Prosthetic Masks',
		price: 30,
		summary: 'Dryad prosthetic mask',
		contents: '1 Dryad prosthetic mask',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A low-ammonia latex dryad prosthetic mask. This can be ordered unpainted, or painted according to your requirements. Prosthetics can be attached using prosthetic adhesive. Alternatively they can be fitted with an elastic strap.',
		images: [
			'/images/Dryad1.jpg',
			'/images/Dryad2.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Strap', selectedChoice: 'No strap', selectedPrice: 0, choices: [
				{choiceName: 'No strap', cost: 0},
				{choiceName: 'Elastic strap', cost: 2}
			]},
	]},
	{
		id: 198,
		name: 'Fey Swan Brow',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 15,
		summary: 'Fey Swan Brow prosthetic',
		contents: '1 Fey Swan Brow prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A Fey Swan Brow prosthetic made from low-ammonia latex. Can be customised with added feathers.',
		images: [
			'/images/ComingSoon.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
			{optionName: 'Feathers', selectedChoice: 'No feathers', selectedPrice: 0, choices: [
				{choiceName: 'No feathers', cost: 0},
				{choiceName: 'Feather trim', cost: 20}
			]},
	]},
	{
		id: 15,
		name: 'Merfolk Nose',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 15,
		summary: 'Merfolk Nose prosthetic',
		contents: '1 Merfolk Nose prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A latex Merfolk Nose prosthetic covers the nose and upper lip. Used at Profound Decisions\' Empire LRP for Merrow lineage.',
		images: [
			'/images/MerfolkNose.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 215,
		name: 'Large Prosthetic Horns',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 20,
		summary: 'Large Prosthetic Horns',
		contents: '1 pair of Large Prosthetic Horns',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A pair of large low-ammonia latex curved horn prosthetics',
		images: [
			'/images/LargeProstheticHorns1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},]
	},
	{
		id: 128,
		name: 'Merfolk Gills A',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 10,
		summary: 'Merfolk Gills prosthetic',
		contents: '1 Merfolk Gills prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A pair of latex gill prosthetics. Used at Profound Decisions\' Empire LRP for Merrow lineage.',
		images: [
			'/images/MerfolkGillsA1.jpg',
			'/images/MerfolkGillsA2.jpg',
			'/images/MerfolkGillsA3.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 221,
		name: 'Merfolk Gills B',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 10,
		summary: 'Merfolk Gills prosthetic',
		contents: '1 Merfolk Gills prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A pair of latex gill prosthetics. Used at Profound Decisions\' Empire LRP for Merrow lineage.',
		images: [
			'/images/MerfolkGillsB1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 222,
		name: 'Merfolk Gills C',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 10,
		summary: 'Merfolk Gills prosthetic',
		contents: '1 Merfolk Gills prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A pair of latex gill prosthetics. Used at Profound Decisions\' Empire LRP for Merrow lineage.',
		images: [
			'/images/MerfolkGillsC1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 223,
		name: 'Merfolk Gills D',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 10,
		summary: 'Merfolk Gills prosthetic',
		contents: '1 Merfolk Gills prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A pair of latex gill prosthetics. Used at Profound Decisions\' Empire LRP for Merrow lineage.',
		images: [
			'/images/MerfolkGillsD1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 173,
		name: 'Merfolk Barbels',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 10,
		summary: 'Merfolk Barbels prosthetic',
		contents: '1 Merfolk Barbels prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A pair of latex Merfolk Barbel prosthetics that attach onto the sides of the neck. Used at Profound Decisions\' Empire LRP for Merrow lineage.',
		images: [
			'/images/ComingSoon.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 228,
		name: 'Medium Prosthetic Horns',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 20,
		summary: 'Medium Prosthetic Horns',
		contents: '1 pair of Medium Prosthetic Horns',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A pair of medium low-ammonia latex curved horn prosthetics',
		images: [
			'/images/ComingSoon.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 196,
		name: 'Sawn-off Horns',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 15,
		summary: 'Sawn-off horns prosthetic',
		contents: '1 pait of sawn-oww horns prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A pair of sawn-off horn prosthetics made from low-ammonia latex.',
		images: [
			'/images/SawnOffHorns1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 214,
		name: 'Small Prosthetic Horns',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 10,
		summary: 'Small Prosthetic Horns',
		contents: '1 pair of Small Prosthetic Horns',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A pair of small low-ammonia latex curved horn prosthetics.',
		images: [
			'/images/SmallProstheticHorns1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 174,
		name: 'Merfolk Scales A',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 10,
		summary: 'Merfolk Scales prosthetic',
		contents: '1 Merfolk Scales prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'A sheet of latex Merfolk Scales that can be cut to size and stuck on to the skin. Used at Profound Decisions\' Empire LRP for Merrow lineage.',
		images: [
			'/images/ComingSoon.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 14,
		name: 'Vampire Brow',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 15,
		summary: 'Vampire Brow prosthetic',
		contents: '1 Vampire Brow prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'Hand-made from low ammonia latex, this Vampire brow prosthetic attached over the nose and eyebrows. This prosthetic can be used at the Profound Decisions\' Empire LRP game to represent the Draughir lineage.',
		images: [
			'/images/VampireBrow1.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 44,
		name: 'Dryad Thorny',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 15,
		summary: 'Dryad thorns prosthetic',
		contents: '1 Dryad thorns prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'Small latex thorn prosthetics for dryads or briar. They can be ordered unpainted, or painted according to your requirements. This prosthetic can be attached using prosthetic adhesive.',
		images: [
			'/images/ComingSoon.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 239,
		name: 'Dryad Thorny Bark',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 15,
		summary: 'Dryad Thorny Bark prosthetic',
		contents: '1 Dryad Thorny Bark prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'Strips of low-ammonia latex bark and thorn prosthetics for dryads or briar. Moulded from real bark with sculpted thorns, these handmade latex prosthetics come pre-painted and can be applied to the face or arms.  These will be supplied in a green / brown colour unless the custom colour option is chosen.',
		images: [
			'/images/DryadThornyBark.png',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 27,
		name: 'Dryad Bark',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 15,
		summary: 'Dryad Bark prosthetic',
		contents: '1 Dryad Bark prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'Strips of low-ammonia latex bark prosthetics for dryads or briar. Moulded from real bark, these handmade latex prosthetics come pre-painted and can be applied to the face or arms.  These will be supplied in a green / brown colour unless the custom colour option is chosen.',
		images: [
			'/images/DryadBark.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Prepainted', cost: 0},
				{choiceName: 'Customer colour', cost: 10}
			]},
	]},
	{
		id: 206,
		name: 'Andler Nubs',
		cat1: 'Prosthetics',
		cat2: 'Facial Prosthetics',
		price: 10,
		summary: 'Antler Nubs prosthetic',
		contents: '1 pair of antler nubs prosthetic',
		shippingPoints: 5,
		status: 'active',
		needsPaint: true,
		description: 'Two pairs of antler nubs made from latex. The first pair are yet to break the skin, whilst the second pair features two small antler tines.',
		images: [
			'/images/AntlerNubs.jpg',
		],
		options: [
			{optionName: 'Finish', selectedChoice: 'Unpainted', selectedPrice: 0, choices: [
				{choiceName: 'Unpainted', cost: 0},
				{choiceName: 'Painted', cost: 10}
			]},
	]},
	{
		id: 209,
		name: 'Medieval Surgery Tools',
		cat1: 'Props',
		cat2: 'Foam',
		price: 75,
		summary: 'Medieval Surgery Tools',
		contents: '1 set of foam surgery tools',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A set of five laser-cut foam medieval surgery tools.',
		images: [
			'/images/MedievalSurgeryTools1.png',
		],
		options: []
	},
	{
		id: 210,
		name: 'Classical Surgery Tools',
		cat1: 'Props',
		cat2: 'Foam',
		price: 75,
		summary: 'Classical Surgery Tools',
		contents: '1 set of foam surgery tools',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A set of five laser-cut foam classical surgery tools.',
		images: [
			'/images/ClassicSurgeryTools1.png',
		],
		options: []
	},
	{
		id: 107,
		name: 'Modern Surgery Tools',
		cat1: 'Props',
		cat2: 'Foam',
		price: 75,
		summary: 'Modern Surgery Tools',
		contents: '1 set of foam surgery tools',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A set of five laser-cut foam modern surgery tools.',
		images: [
			'/images/ModernSurgeryTools1.png',
		],
		options: []
	},
	{
		id: 211,
		name: 'Sinister Tools',
		cat1: 'Props',
		cat2: 'Foam',
		price: 120,
		summary: 'Sinister Tools',
		contents: '1 set of sinister tools',
		shippingPoints: 10,
		status: 'active',
		needsPaint: true,
		description: 'A set of five laser-cut foam slasher tools.',
		images: [
			'/images/SinisterTools.png',
		],
		options: []
	},
	{
		id: 212,
		name: 'Chef Knives',
		cat1: 'Props',
		cat2: 'Foam',
		price: 120,
		summary: 'Chef\'s Knives',
		contents: '1 set of chef\'s knives',
		shippingPoints: 75,
		status: 'active',
		needsPaint: true,
		description: 'A set of six laser-cut foam Japanese-style chef\'s knives.',
		images: [
			'/images/ChefKnives.png',
		],
		options: []
	},
]

export default data
import React, {useState} from 'react';

import videoData from '../../data/videoData'

const Video = () => {
    const videos = videoData
    const [currentVideo, setCurrentVideo] = useState('Tgb0_qmsmJY')
    const [activePlayList, setActivePlayList] = useState('PLpQ42_p6GeI3X-tt2S81L9ntZC1_9o7IX')

    const renderMenuItem = (series) => {
        const active = series.playListId === activePlayList ? 'active-' : ''
        const items = series.seriesEpisodes.map(episode => renderEpisode(episode))
        const subMenu = active ? <div className="video-sub-menu">{items}</div> : ''
        const handleClickSeries = () => {setActivePlayList(series.playListId)}
        return <div className={active + 'video-menu'} onClick={handleClickSeries} key={series.playListId}>{series.seriesTitle}{subMenu}</div>
    }

    const renderEpisode = (episode) => {
        const handleClickEpisode = () => {setCurrentVideo(episode.episodeId)}
        return <div className="video-item" onClick={handleClickEpisode} key={episode.episodeId}>{episode.episodeTitle}</div>
    }

    return (
        <div className="video-container">
            <div className="video-menu-container">
                {videos.map(series => renderMenuItem(series))}
            </div>
            <div className="video-wrapper">
                    <iframe src={`https://www.youtube.com/embed/${currentVideo}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default Video
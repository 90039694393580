import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import filterReducer from './filterReducer'
import cartReducer from './cartReducer'
import modalReducer from './modalReducer'

export default combineReducers({
    items: filterReducer,
    cart: cartReducer,
    modal: modalReducer,
    form: formReducer
})
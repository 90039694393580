import React from 'react';
import {Link} from 'react-router-dom'

const Header = () => {
  return (
      <header>
            <Link to="/">Home</Link>
            <Link to="/shopfront">Shop</Link>
            <Link to="/cart">Cart</Link>
            <Link to="/tutorials">Videos</Link>
      </header>
  )
};

export default Header;
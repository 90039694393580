import {OPEN_MODAL, CLOSE_MODAL, CHANGE_QUANTITY} from '../actions/Types';

import data from '../data/data';

const INITIAL_STATE = {
    showModal: false,
    modalContent: null,
    quantity: 1,
};

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {...state, showModal: true, modalContent: action.payload}
        case CLOSE_MODAL:
            return {...state, showModal: false, modalContent: null}
        case CHANGE_QUANTITY:
            return {...state, quantity: action.payload}
        default:
            return state
    }
};
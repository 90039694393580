import React from 'react';
import {connect} from 'react-redux'

import CartItem from './CartItem'
import OrderInfo from './OrderInfo'

const Cart = (props) => {
    const currentCart = props.cartItems

    const getPaymentAndShipping = (itemList) => {
        var totalCost = 0;
        var shippingPoints = 0;
    
        itemList.forEach(item => {
            totalCost = totalCost + (item.price * item.quantity)
            shippingPoints = shippingPoints + (item.shippingPoints * item.quantity)
        })
    
        return [totalCost, shippingPoints]
    }

    const [totalCost, shippingPoints] = getPaymentAndShipping(currentCart)
    
    return (
        <div className="cart">
            <ul className="cart-items">
                {currentCart.map((item) => <CartItem item={item} />)}
            </ul>
            <div className="form-container">
                <OrderInfo totalCost={totalCost} shippingPoints={shippingPoints} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        cartItems: state.cart.cartItems,
        ready: state.cart.readyForPayment
    }
}

export default connect(mapStateToProps)(Cart)

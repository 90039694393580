import React from 'react'

const FrontPage = () => {
    return (
//        <div className="background-image">
//            <p>Mandala Studios is the workshop base of several crafters based in Coventry, United Kingdom. Our varied team has many years of experience in providing bespoke work, drawing on a large pool of highly skilled workers with many areas of expertise.</p>
//            <p>Our mission is to provide a quality service by caring about your project as much as you do. Typical requests range from highly personal one off items to large mass produced rush-deadline orders but all are treated with equal importance, and all must meet our high standards for quality and consistency.</p>
//            <p>Our focus areas include: Costuming, Masks, Prosthetics and our own range of Makeup, Theatrical (Poly-urea) Armour and spraying facilities, Leatherwork - Masks, Armour and other Accessories, Theatrical (LRP) Weapons, Set building, Props, and Large Creatures along with many other things - if we don't do it, we often know someone who does.</p>
//            <p>For stock items look in our shop section, for more custom requirements please contact us directly using the commissions tab at the top of the page.</p>
//            <p>We ship to customers all over the world, from the US to Australia and everywhere in between!</p>
//        </div>

    <div className="container">
        <div className="frontpage home">
            <h1>Mandala Studios</h1>
            <p>Crafting Imagination Into Reality</p>
        </div>
        <div className="frontpage commissions">
            <h1>Commissions</h1>
            <p>Sometimes you want something that can't be bought in a store. Actually thats how half our products came about! We are able to make pretty much anything you can imagine and where we can't do something, we almost certainly know someone who can.</p>
            <p>From slow lovingly crafted high quality items to crazy deadline, hugely ambitious projects, we can usually come up with a plan to suit your timeline and budget.</p>
            <p>please email: info@mandalastudios.co.uk with details of what you need</p>
        </div>
        <div className="frontpage masks">
            <h1>Masks</h1>
            <p>For over two decades Mandala has specialised hand made latex masks for LRP, film and other entertainment, from unique designs to batch production of 100s of masks.</p>
            <p>A wide range of standard items are availabe in the shop or contact us to discuss individuial commissions.</p>
        </div>
        <div className="frontpage monsters">
            <h1>Monsters</h1>
            <p>We started out building large monster costumes and have only gotten better - our Werewolf costumes are used at events across Europe.</p>
            <p>We design and build costumes for your uniue use case and can ship to anywhere in the world, contact us for more information.</p>
        </div>
        <div className="frontpage costume">
            <h1>Costumes</h1>
            <p>We make concept costumes, from a Warhammer 40,000 inspired Skitarii to an Orcish general.  We rarely make individual pieces of clothing, but if you have a character and need the costume for it we can help.</p>
        </div>
        <div className="frontpage weapons">
            <h1>Weapons and Shields</h1>
            <p>We specialise in individual / small batch foam latex weapons and shields for live action role play, film and TV.  Bespoke weapons and shileds, individual equipment or matching pieces for a whole group can be created.</p>
        </div>
        <div className="frontpage sfx">
            <h1>Special Effects</h1>
            <p>We provide equipment hire and specialist services for live action role play events and others.  Our equipment covers sound, lighting and pyrotechnics.</p>
            <p>Talk to us about your event or encounter and we can provide planning and design or even come and run the special effects.</p>
        </div>
        <div className="frontpage contact">
            <h1>Contact us</h1>
            <p>The primary way to contact us is via email - often we are elbow deep in various noxious chemicals, or juggling moulds and costume components so unable to get to the phone.</p>
            <p>Our email address is info@mandalastudios.co.uk</p>
            <p>You can call and leave us a message - however this is run through a web based voicemail service so you're likely to receive a quicker response by email.  Our voicemail number is +44(0)2476 992 666</p>
            <p>You can even write to us at Mandala Studios, Unit 9, 4-10 Argyll Street, Coventry, CV2 4FL</p>
        </div>
        <div className="frontpage about">
            <h1>About us</h1>
            <p>Mandala Studios is the workshop base of several crafters based in Coventry, United Kingdom. Our varied team has many years of experience in providing bespoke work, drawing on a large pool of highly skilled workers with many areas of expertise.</p>
        </div>

    </div>



        )
}

export default FrontPage
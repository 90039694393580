export const FILTER = 'FILTER'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const ADD_TO_CART = 'ADD_TO_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY'
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS'
export const ORDER = 'ORDER'
export const SEARCH = 'SEARCH'
export const PROCEED_TO_PAYMENT = 'PROCEED_TO_PAYMENT'
export const ORDER_COMPLETE = 'ORDER_COMPLETE'
import React from 'react'
import { connect } from 'react-redux'
import { changeQuantity } from '../../actions'

const QuantityPicker = (props) => {
    
    const increment = () => {
        const newValue = props.quantity + 1
        props.changeQuantity(newValue)
    }

    const decrement = () => {
        const newValue = props.quantity - 1
        if (newValue > 0) {
            props.changeQuantity(newValue)
        }
    }

    return (
        <div className="option">
            Quantity:&nbsp;
            <button onClick={decrement}>-</button>
            &nbsp;{props.quantity}&nbsp;
            <button onClick={increment}>+</button>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        quantity: state.modal.quantity
    }
}

export default connect(mapStateToProps, {changeQuantity})(QuantityPicker)
import React from 'react';
import {Routes, Route, Link} from 'react-router-dom';

import Header from './Header'
import Shop from './shop/Shop'
import Cart from './shop/Cart'
import Video from './videos/Video'
import Commissions from './Commissions'
import FrontPage from './FrontPage'
import ShopFront from './shop/ShopFront'

const App = () => {
    return (
    <div className="grid-container">
        <Header />
            <Routes>
                <Route path="/" element={<FrontPage />} />
                <Route path="/test" element={<FrontPage />} />
                <Route path="shop" element={<ShopFront />} />
                <Route path="shop/id/:id" element={<Shop />} />
                <Route path="shop/category/:initialCategory" element={<Shop />} />
                <Route path="shopfront" element={<ShopFront />} />
                <Route path="tutorials" element={<Video />} />
                <Route path="commissions" element={<Commissions />} />
                <Route path="cart" element={<Cart />} />
            </Routes>
        <footer>
            <p>Mandala Studios - Props, Costumes & Special Effects</p>
            <p>2nd Floor, Holt Court, 16 Warwick Row</p>
        </footer>
    </div>
    )
}

export default App
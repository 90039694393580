import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux'

import {addToCart, closeModal, changeQuantity} from '../../actions'
import QuantityPicker from './QuantityPicker'
import Carousel from './Carousel'

const ItemDetail = (props) => {
    const [item] = props.allItems.filter(i => i.id === props.item.id)
    const [itemOptions, setItemOptions] = useState([{}])

    useEffect(() => {
        //Ensure quantity is reset when loading new product
        props.changeQuantity(1)
        var initialOptions = []
        item.options.forEach(option => initialOptions.push({type: option.optionName, chosen: option.selectedChoice, cost: option.selectedPrice}))
        if (item.needsPaint) {
            item.options.unshift({optionName: 'Colour', selectedChoice:  '#000000', selectedPrice: 0, choices: [{choiceName: 'Colour', cost: 0}]})
            item.needsPaint = false
        }
        setItemOptions(initialOptions)
    }, [])

    const formatOptions = (options) => {
        const formattedOptions = options.map((option) =>
            <div className="option">
                    <label htmlFor={option.optionName}>{option.optionName}: </label>
                    {option.optionName === 'Colour' ? <input type="color" name="Colour" key="Colour" onChange={updateOptions} /> : 
                        <select 
                            name={option.optionName}
                            key={option.optionName}
                            onChange={updateOptions}
                        >
                            {formatChoices(option.choices)}
                        </select>
                    }
            </div>
        )

        console.log(itemOptions)

        return formattedOptions
    }

    const formatChoices = (choices) => {
        const formattedChoices = choices.map((choice) => 
        <option
                name={choice.choiceName}
                value={choice.choiceName}
                key={choice.choiceName}
            >
                {choice.choiceName} + £{choice.cost}
            </option>)
        return formattedChoices
    }

    const getPrice = () => {
        const optionCost = itemOptions.reduce((previous, current) => 
            {
                return previous + current.cost
            }, 0)
        return (item.price + optionCost)
    }

    const getChosenOption = (e) => {    
        const [option] = props.item.options.filter(option => option.optionName === e.target.name)
        const [chosenObject] = option.choices.filter(choice => choice.choiceName === e.target.value || choice.choiceName === 'Colour')
        const cost = chosenObject.cost
        return {type: option.optionName, chosen: e.target.value, cost: cost}
    }

    const updateOptions = (e) => {
        const currentChoices = itemOptions
        const newChoice = getChosenOption(e)
        
        const newList = currentChoices.filter(choice => {
            return choice.type !== newChoice.type
        })

        newList.push(newChoice)

        setItemOptions(newList)
    }

    const checkForDuplicate = (itemList, newItem) => {
        let match = false

        if (itemList.length === 0) {
            return false
        }

        itemList.forEach((item, index) => {
            if(item.name !== newItem.name) {
                return false
            } else {
                if(item.options.length !== newItem.options.length) {
                    return false
                } else {
                    const itemList = item.options.map(option => option.chosen).sort()
                    const newItemList = newItem.options.map(option => option.chosen).sort()
                    
                    for (let i = 0; i < itemList.length; i++) {
                        if (itemList[i] !== newItemList[i]) {
                            return false
                        }
                    }
                    match = index
                }
            }
        })
        return match        
    }

    const prepForCart = () => {
        const cartItem = {...item}
        const currentCart = props.cart.cartItems
        var newCart

        cartItem.options = itemOptions

        const itemIndex = checkForDuplicate(currentCart, cartItem)
        
        if (itemIndex || itemIndex === 0) {
            currentCart[itemIndex].quantity ++
            newCart = currentCart
        } else {
            cartItem.id = currentCart.length + 1
            cartItem.price = getPrice()
            delete cartItem.summary
            delete cartItem.contents
            delete cartItem.status
            delete cartItem.description
            cartItem.quantity = props.modal.quantity

            newCart = [...props.cart.cartItems, cartItem]
        }

        localStorage.setItem('cart', JSON.stringify(newCart))
        props.addToCart(newCart)
        props.closeModal()
    }

    return (
        <div className="product-details">
            <div className="product-details-options">
                <div className="carousel">
                    {item.images ? <div><Carousel images={item.images} /></div> : <div>PICTURE GOES HERE</div>}
                </div>
                <div className="options">
                    {formatOptions(item.options)}
                    <QuantityPicker />
                    <div className="option">Price: £{getPrice() * props.modal.quantity}</div>
                    <div className="option"><button onClick={prepForCart}>Add to cart</button></div>
                </div>
            </div>
            <div className="product-details-description">
                <p><strong>{item.name}</strong></p>
                <p>{item.description}</p>
            </div>
        </div>
    )

}

const mapStateToProps = (state) =>{
    return {
        allItems: state.items.allItems,
        cart: state.cart,
        modal: state.modal
    }
}

export default connect (mapStateToProps, {addToCart, closeModal, changeQuantity})(ItemDetail)
import {FILTER, CLEAR_FILTER, UPDATE_CURRENT_ITEM, CLEAR_CURRENT_ITEM, UPDATE_CART_ITEMS, ORDER, SEARCH} from './Types';
import { ADD_TO_CART, CLEAR_CART, PROCEED_TO_PAYMENT, ORDER_COMPLETE } from "./Types";
import { OPEN_MODAL, CLOSE_MODAL, CHANGE_QUANTITY} from "./Types";

export const applyFilter = (category) => {
    return {
        type: FILTER,
        payload: category
    }
}

export const applyOrder = (order) => {
    return {
        type: ORDER,
        payload: order
    }
}

export const applySearch = (term) => {
    return {
        type: SEARCH,
        payload: term
    }
} 

export const clearFilter = () => {
    return {
        type: CLEAR_FILTER
    }
}

export const addToCart = (item) => {
    return {
        type: ADD_TO_CART,
        payload: item
    }
}

export const updateCart = (newCart) => {
    return {
        type: UPDATE_CART_ITEMS,
        payload: newCart
    }
}

export const clearCart = () => {
    return {
        type: CLEAR_CART
    }
}

export const openModal = (item) => {
    return {
        type: OPEN_MODAL,
        payload: item
    } 
}

export const closeModal = () => {
    return {
        type: CLOSE_MODAL
    }
}

export const changeQuantity = (newQuantity) => {
    return {
        type: CHANGE_QUANTITY,
        payload: newQuantity
    }
}

export const payment = (newReadiness) => {
    return {
        type: PROCEED_TO_PAYMENT,
        payload: newReadiness
    }
}

export const orderComplete = (order) => {
    return {
        type: ORDER_COMPLETE,
        payload: order
    }
}
import React from 'react';
import {Field, reduxForm, formValueSelector} from 'redux-form'
import {connect} from 'react-redux'
import emailjs, {init} from '@emailjs/browser';
import {PayPalButton} from 'react-paypal-button-v2'

import {payment, orderComplete, clearCart} from '../../actions'

const selector = formValueSelector('OrderInfo')

class OrderInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            shippingMethod: 'UK'
        }
    }
    
    getShipping = (points, type) => {

        if (type === 'UK') {
            switch (true) {
                case (points < 10):
                    return 5
                case (points >= 10 && points < 50 ):
                    return 7.5
                case (points >= 50 && points < 100):
                    return 10
                case (points > 100):
                    return 'custom'
                default:
                    return 0
            }
        }

        if (type === 'Europe') {
            switch (true) {
                case (points < 10):
                    return 10
                case (points >= 10 && points < 50 ):
                    return 15
                case (points >= 50 && points < 100):
                    return 20
                case (points > 100):
                    return 'custom'
                default:
                    return 0
            }
        }
        if (type === 'International') {
            switch (true) {
                case (points < 10):
                    return 15
                case (points >= 10 && points < 50 ):
                    return 25
                case (points >= 50 && points < 100):
                    return 30
                case (points > 100):
                    return 'custom'
                default:
                    return 0
            }
        }
    }

    renderInput = ({input, label, meta}) => {
        return (
            <li>
                <label>{label}</label>
                <input {...input} />
                {renderError(meta)}
            </li>
        )
    }

    renderRadio = ({input, label, meta}) => {
        return (
            <input type="radio" {...input} />
        )
    }

    renderArea = ({input, label, meta}) => {
        return(
            <li>
                <label>{label}</label>
                <textarea rows="4" {...input} />
                {renderError(meta)}
            </li>
        )
    }

    submitOrder = (order, id) => {
        const clearCart = this.props.clearCart
        console.log('submitOrder called')
        const address = `${order.address1}, ${order.address2}, ${order.address3}, ${order.address4}`

        var formattedItems = ''

        order.items.forEach(item => {
            formattedItems = formattedItems + `${item.quantity} x <strong>${item.name}</strong> - `
            if (item.options && item.options !== []){
                    item.options.forEach(option => {
                    formattedItems = formattedItems + `${option.type}: ${option.chosen}, `
                })
            }
            formattedItems = formattedItems + `<br />`;
        });

        init("lYP7KpCXCX5a8qw0t")
        emailjs.send('Mandala', 'template_rtpxur4', {
            timestamp: order.date.toString(),
            name: order.name,
            email: order.email,
            address: address,
            notes: order.notes,
            shipping: order.shipping,
            items: formattedItems,
            id: id
        })
        .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
            localStorage.clear()
            clearCart()
            window.alert('Thanks for your order!')
            window.location.reload()
         }, function(error) {
            console.log('FAILED...', error);
         })

        console.log('submitOrder completed')
    }

    onSubmit = (formValues) => {
        this.props.payment(true)

        const order = {
            date: Date.now(),
            name: formValues.Name,
            email: formValues.Email,
            address1: formValues.Address1,
            address2: formValues.Address2,
            address3: formValues.Address3,
            address4: formValues.Address4,
            notes: formValues.OrderNotes,
            shipping: formValues.Shipping,
            items: this.props.currentCart,
        }
        this.props.orderComplete(order)
    }


    render() {  
        const shippingCost = this.getShipping(this.props.shippingPoints, this.props.shippingMethod) || 0
        const shippingDisplay = shippingCost === 'custom' ? 'This is a large order that is unlikely to fit any of our standard shipping solutions.  If you submit this order we will contact you to discuss the cost of shipping, alternatively if you\'d like to discuss potential costs before submitting the order please email info@mandalastudios.co.uk' : `Shipping: £${shippingCost}`
        const totalDisplay = shippingCost === 'custom' ? '' : `Total: £${this.props.totalCost + shippingCost}`
        var shippingDescriptor = ''
        if (this.props.shippingMethod === 'UK') { shippingDescriptor = 'Shipping to any UK address'}
            else if (this.props.shippingMethod === 'Europe') { shippingDescriptor = 'Shipping to any address in Europe'}
            else if (this.props.shippingMethod === 'International') { shippingDescriptor = 'Shipping to any address outside of the UK & Europe'}
            else if (this.props.shippingMethod === 'Collect') { shippingDescriptor = 'Collect your order from the workshop in Coventry or any event Mandala is trading at.  Each year we trade at LarpCon and all four of Profound Decisions\' main Empire events.  Please mention which event you\'d like to collect from in the order notes.'}
            

            return (
            <div>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui form error"> 
                    <Field name="Name" component={this.renderInput} label="Name:" />
                    <Field name="Email" component={this.renderInput} label="Email:" />
                    <Field name="Address1" component={this.renderInput} label="Address:" />
                    <Field name="Address2" component={this.renderInput} />
                    <Field name="Address3" component={this.renderInput} />
                    <Field name="Address4" component={this.renderInput} label="Post code:"/>
                    <Field name="OrderNotes" component={this.renderArea} label="Order Notes:" />
                    <label>Shipping</label>
                    <div>
                        <label>
                            <Field name="Shipping" component={this.renderRadio} type="radio" value="UK" />{' '}
                            UK
                        </label>
                        <label>
                            <Field name="Shipping" component={this.renderRadio} type="radio" value="Europe" />{' '}
                            Europe
                        </label>
                        <label>
                            <Field name="Shipping" component={this.renderRadio} type="radio" value="International" />{' '}
                            Rest of World
                        </label>
                        <label>
                            <Field name="Shipping" component={this.renderRadio} type="radio" value="Collect" />{' '}
                            Collection
                        </label>
                        <p>{shippingDescriptor}</p>
                    </div>

                    
                    <div>
                        <p>Sub total: £{this.props.totalCost}</p>
                        <p>{shippingDisplay}</p>
                        <p>{totalDisplay}</p>
                        <p>Please note that nearly all of our products are handmade by us in the UK. We do not carry high levels of stock so it is very likely that we will need to make your order before shipping. Because of this we set expectations that you should allow 28 days for delivery. If you have an urgent deadline then we will do our best to meet it, but if it is critical you receive your product earlier than this, we ask that you contact us first to check that this is possible.</p>
                    </div>
                    <button className="ui primary button" disabled={!this.props.shippingMethod}>Proceed to payment</button>
                </form>
                <br />
                {this.props.ready && 
                    <PayPalButton 
                        amount={this.props.totalCost + shippingCost}
                        shippingPreference="NO_SHIPPING"
                        onSuccess={(details, data) => {
                            this.submitOrder(this.props.order, details.id)
                        }}
                        options={{
                            clientId: 'AdhsNE2Lc27bTlaf254oRb-ET6hHVUgfutRA5Ti5JYQ2-MNaHC3nQj0J6iohCZrJYtFrtWiTAayJxdtU',
                            currency: 'GBP'
                        }}
                    />
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentCart: state.cart.cartItems,
        shippingMethod: selector(state, 'Shipping'),
        ready: state.cart.readyForPayment,
        order: state.cart.completedOrder
    }
}

OrderInfo = connect(mapStateToProps, {payment: payment, orderComplete: orderComplete, clearCart: clearCart})(OrderInfo)

const renderError = ({error, touched}) => {
    if (touched && error) {
        return (
            <div className="ui error message">
                <div className="header">{error}</div>
            </div>
        )
    }
}

const validate = (formValues) => {
    const errors = {}

    if (!formValues.Name) {
        errors.Name='Please enter your name'
    }

    if (!formValues.Email) {
        errors.Email='Please enter your email address'
    }

    if (!formValues.Address1) {
        errors.Address1='Please enter the first line of your address'
    }

    if (!formValues.Address4) {
        errors.Address4='Please enter your post code'
    }

    if (!formValues.Shipping) {
        errors.Shipping='Please select a shipping type'
    }
    
    return errors
}

export default reduxForm({
    form: 'OrderInfo',
    validate: validate
})(OrderInfo)
import {React, useState} from 'react';

const Carousel = (props) => {
    const [selectedImage, setSelectedImage] = useState(0)
    
    const changeImage = (index) => {
        setSelectedImage(index)
    }
    
    const imagebar = props.images.map((image, index) => {
        if (index !== selectedImage) {
            return <img className="carousel-option" onClick={() => changeImage(index) } height="45" key={index} src={image} />
        }
    })

    return (
        <div>
            <img className="carousel-main" src={props.images[selectedImage]} />
            { props.images.length > 1 &&
                <div>
                    {imagebar}
                </div>
        }
        </div>
    )
}

export default Carousel
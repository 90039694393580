const videoData = [
    {
        seriesTitle: 'Making a Severed Head',
        playListId: 'PLpQ42_p6GeI3X-tt2S81L9ntZC1_9o7IX',
        seriesEpisodes: [
            {episodeTitle: 'Part 1: Sculpture', episodeId: 'Tgb0_qmsmJY'},
            {episodeTitle: 'Part 2: Moulding', episodeId: '6ihlPcH3zXA'},
            {episodeTitle: 'Part 3: Casting', episodeId: 'RGVUqv7dcKQ'},
            {episodeTitle: 'Part 4: Detail Fabrication', episodeId: '6XJmSfu2_nc'},
            {episodeTitle: 'Part 5: Painting & Flocking', episodeId: 'ym0gTf1G9N8'},
        ]
    },
    {
        seriesTitle: 'Making an Oversized Mask',
        playListId: 'PLpQ42_p6GeI2WMyG1dnBzamZkzMWUt0Wh',
        seriesEpisodes: [
            {episodeTitle: 'Part 1: Polycarving', episodeId: 'x8v_Zqjx4Vk'},
            {episodeTitle: 'Part 2: Sculpting', episodeId: 'C4Fqln-FRzc'},
            {episodeTitle: 'Part 3: Plaster Moulding', episodeId: '7s4pY0QXEHM'},
            {episodeTitle: 'Part 4: Reinforced Latex Casting', episodeId: 'tgsr30E1G4E'},
            {episodeTitle: 'Part 5: Painting & Finishing', episodeId: '8NvdqNcMtV0'},
        ]
    },
    {
        seriesTitle: 'Making Foam Antlers',
        playListId: 'PLpQ42_p6GeI1Dwo-FfN1X_PJZOLIWtGqS',
        seriesEpisodes: [
            {episodeTitle: 'Part 1: Foam Fabrication', episodeId: 'ck3_Nr7RR6U'},
            {episodeTitle: 'Part 2: Painting', episodeId: 'IVvII52wFCQ'},
        ]
    },
    {
        seriesTitle: 'Making a Foam Book',
        playListId: 'PLpQ42_p6GeI2KwLoET9XFws7TZX3FlGHu',
        seriesEpisodes: [
            {episodeTitle: 'Part 1: Foam Fabrication', episodeId: 'fgToXW4u3s0'},
            {episodeTitle: 'Part 2: Latexing', episodeId: 'efI96B9-UKw'},
        ]
    },
    {
        seriesTitle: 'Making a Werewolf Mask',
        playListId: 'PLpQ42_p6GeI3tnMoHCo6ml7piby3mf0rW',
        seriesEpisodes: [
            {episodeTitle: 'Part 1: Latex Casting & Painting', episodeId: '7VT3qKEphu8'},
            {episodeTitle: 'Part 2: Fur Hood', episodeId: 'ItEO7dhdHkQ'},
            {episodeTitle: 'Part 3: Fitting the Hood & Airbrushing', episodeId: 'lH2BBTlQFRA'},
        ]
    },
    {
        seriesTitle: 'Making a Fishy Head',
        playListId: 'PLpQ42_p6GeI0Fu1HPnspa_8GwtDTa8W4p',
        seriesEpisodes: [
            {episodeTitle: 'Part 1: Sculpting', episodeId: 'XtwFs-tGIDo'},
            {episodeTitle: 'Part 2: Plaster Moulding ', episodeId: 'x0RUn0XGrbk'},
            {episodeTitle: 'Part 3: Latex Casting', episodeId: '8uOXkMQkZtI'},
            {episodeTitle: 'Part 4: Detail Fabrication', episodeId: 'XtwFs-tGIDo'},
            {episodeTitle: 'Part 5: Painting', episodeId: 'Fg75aK1q6Ic'},
            {episodeTitle: '', episodeId: ''},
        ]
    },
    {
        seriesTitle: 'Making a Goat Mask',
        playListId: 'PLpQ42_p6GeI3X-PLpQ42_p6GeI0VyNumYXDMo-_cJhk32yVA',
        seriesEpisodes: [
            {episodeTitle: 'Part 1: Sculpting in WED Clay', episodeId: 'q2d3y3mkKZg'},
            {episodeTitle: 'Part 2: Plaster Moulding', episodeId: 'KouSdrDXCVI'},
            {episodeTitle: 'Part 3: Latex & Polyfoam Carving', episodeId: 'T2a6scJBJeo'},
            {episodeTitle: 'Part 4: Painting, Flocking & Finishing', episodeId: 'wd7i-ld5JRA'},
        ]
    },
    {
        seriesTitle: 'Making a Foam Cricket Bat',
        playListId: 'PLpQ42_p6GeI2ysWL8SMM8AirYRzBLr21g',
        seriesEpisodes: [
            {episodeTitle: 'Part 1: Fabrication', episodeId: 'QaI31RHcUeI'},
            {episodeTitle: 'Part 2: Latexing', episodeId: 'dXpB-HAD4LE'},
        ]
    },
    {
        seriesTitle: 'Making a Grinch Mask',
        playListId: 'PLpQ42_p6GeI18WOoFm2p7GAgXWLf2n0g7',
        seriesEpisodes: [
            {episodeTitle: 'Part 1: Sculpting in WED Clay', episodeId: 'v7-sBy-uapA'},
            {episodeTitle: 'Part 2: Plaster Moulding', episodeId: 'IMTpdQp1yto'},
            {episodeTitle: 'Part 3: Casting, Mounting & Painting', episodeId: 'ywCX27X5EJM'},
            {episodeTitle: 'Part 4: Fur Hood', episodeId: 'FmjLcb0IBrg'},
        ]
    },
]

export default videoData
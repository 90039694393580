import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import Modal from 'react-modal'
import {useParams} from 'react-router-dom'

import Item from './Item'
import ItemDetail from './ItemDetail'
import Filter from './Filter'
import { openModal, closeModal } from '../../actions/';


const Shop = (props) => {
    var filteredList, searchedList, orderedList
    
    if (props.filterCategory !== 'All Categories') {
        filteredList =  props.items.filter(item => item.cat2 === props.filterCategory)
    } else {
        filteredList = props.items
    }

    if (props.filterTerm !== '') {
        searchedList = filteredList.filter(item => item.name.toLowerCase().includes(props.filterTerm.toLowerCase()) || item.description.toLowerCase().includes(props.filterTerm.toLowerCase()))
    } else {
        searchedList = filteredList
    }
    
    if (props.filterOrder === 'price'){
        orderedList = searchedList.sort((a, b) => a.price - b.price)
    } else if (props.filterOrder === 'alphabetical') {
        orderedList = searchedList.sort((a, b) => {
            const result = a.name > b.name ? 1 : -1
            return result
        })
    } else {
        orderedList = searchedList
    }

    const {id} = useParams()

    useEffect(() => {
        if (id) {
            const item = props.items.find(item => item.id === Number(id))
            props.openModal(item)
        }
    }, [])

    return (
        <div>
            <div><Filter /></div>
            <ul className="products">
                {orderedList.map((item) => <Item item={item} key={item.id} />)}
            </ul>
            {props.modal.modalContent && 
                <Modal isOpen={props.modal.showModal} ariaHideApp={false}>
                    <div>
                        <button className="close-modal" onClick={props.closeModal}>x</button>
                    </div>
                    <ItemDetail item={props.modal.modalContent} />
                </Modal>
            }
        </div>
        
    )
}

const mapStateToProps = (state) => {
    return {
        items: state.items.allItems,
        filterCategory: state.items.filterCategory,
        filterOrder: state.items.filterOrder,
        filterTerm: state.items.filterTerm,
        modal: state.modal
    }
}

export default connect (mapStateToProps, {openModal, closeModal})(Shop)
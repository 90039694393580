import React from 'react';
import {connect} from 'react-redux'

import { openModal } from '../../actions/';

const Item = (props) => {
    const [item] = props.allItems.filter(i => i.id === props.item.id)

    const showItem = () => {
        props.openModal(item)
    }
    
    return (
        <div>
            <li key={item.id}>
                <div className="product">
                    <img src={item.images[0]} onClick={showItem} width="175" />
                    <p>{item.name}</p>
                    <div className="product-price">
                        <div>£{`${item.price}`}</div>
                        <button onClick={showItem} className="button primary">View</button>
                    </div>
                </div>
            </li>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        allItems: state.items.allItems,
        modal: state.modal
    }
}

export default connect(mapStateToProps, {openModal})(Item);
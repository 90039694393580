import {FILTER, ORDER, SEARCH, CLEAR_FILTER} from '../actions/Types';

import data from '../data/data';

const INITIAL_STATE = {
    allItems: data,
    filterCategory: 'All Products',
    filterOrder: 'Order By',
    filterTerm: ''
};

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case FILTER:
            return {...state, filterCategory: action.payload}
        case ORDER:
            return {...state, filterOrder: action.payload}
        case SEARCH:
            return {...state, filterTerm: action.payload}
        case CLEAR_FILTER:
            return {...state, filterCategory: 'All Categories', filterOrder: 'Alphabetical', filterTerm: ''}
        default:
            return state
    }
};
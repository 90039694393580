import {ADD_TO_CART, CLEAR_CART, UPDATE_CART_ITEMS, PROCEED_TO_PAYMENT, ORDER_COMPLETE} from '../actions/Types'

const INITIAL_STATE = {
    cartItems: JSON.parse(localStorage.getItem('cart')) || [],
    readyForPayment: false,
    completedOrder: {}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            return {...state, cartItems: action.payload}
        case CLEAR_CART:
            return {...state, cartItems: [], completedOrder: {}}
        case UPDATE_CART_ITEMS:
            return {...state, cartItems: action.payload}
        case PROCEED_TO_PAYMENT:
            return {...state, readyForPayment: action.payload}
        case ORDER_COMPLETE:
            return {...state,  completedOrder: action.payload}
        default:
            return state
    }
}
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

import {closeModal} from '../../actions'

const ShopFront = (props) => {
    const [activeCategory, setActiveCategory] = useState('')

    useEffect(() => {
        props.closeModal()
    })

    const filterList = props.items.map(item => item.cat1).filter((value, index, self) => self.indexOf(value) === index)

    const subMenu = () => {
        const tempList = props.items.filter(item => item.cat1 === activeCategory)
        const catList = tempList.map(item => item.cat2).filter((value, index, self) => self.indexOf(value) === index)
        return catList.map(category => <Link to={`/shop/category/${category}`}>{category}</Link>)
    }

    const formatSplash = (category) => {
        const info = props.items.find(item => item.cat1 === category)

        const handleMouseEnter = () => {
            setActiveCategory(info.cat1)
        }

        const handleMouseLeave = () => {
            setActiveCategory('')
        }
        
        return (
            <div className="primarySplash" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img src={info.images[0]} height="150" />
                <h3>{info.cat1}</h3>
                {info.cat1 === activeCategory ? <div className="secondarySplash">{subMenu()}</div> : ''}
            </div>
        )
    }

    return (
        <div className="shopfront">
            <div className="splashContainer">
                {filterList.map(category => formatSplash(category))}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        items: state.items.allItems
    }
}

export default connect(mapStateToProps, {closeModal})(ShopFront)